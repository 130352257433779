import React, { useState } from 'react';
import { Box, Card, CardContent, Typography, Accordion, AccordionSummary, AccordionDetails, Fade } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const MobileFramedImage = ({ src, alt }) => {
    return (
        <Box
            sx={{
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '16px',
                overflow: 'hidden',
                boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
                p: 1,
            }}
        >
            <img
                src={src}
                alt={alt}
                width={40}
                height={40}
                style={{
                    display: 'block',
                }}
            />
        </Box>
    );
};

const WebProblemsGridMobile = () => {
    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const problems = [
        { icon: "broken.png", title: "Unstable", text: "Pages change their internal structure, making conventional automations fail." },
        { icon: "money.png", title: "Expensive", text: "Conventional tools require constant maintenance and expensive intervention." },
        { icon: "mess.png", title: "Messy", text: "Traditional methods do not understand the meaning behind data being processed." },
        { icon: "puzzle.png", title: "Incomplete", text: "Extracted data is often incomplete or inconsistent and requires manual correction." },
    ];

    return (
        <Box sx={{
            background: 'linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) calc(100% - 90px), rgba(255,255,255,0) 100%)',
            width: '100vw',
            marginLeft: 'calc(-50vw + 50%)',
            marginRight: 'calc(-50vw + 50%)',
            py: 4,
            boxShadow: '0 -15px 20px -15px rgba(0, 0, 0, 0.3)',
        }}>
            <Box sx={{
                maxWidth: 400,
                margin: '0 auto',
                px: 2,
            }}>
                <Typography
                    variant="subtitle1"
                    sx={{
                        textAlign: "center",
                        lineHeight: 1.2,
                        mb: 2,
                    }}
                    fontWeight="bold"
                >
                    Getting data from the web is hard
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 2, textAlign: "center" }}>
                    While the demand for data is skyrocketing, data acquisition costs remain unchanged.
                </Typography>
                <Box>
                    {problems.map((problem, index) => (
                        <Accordion
                            key={index}
                            expanded={expanded === `panel${index}`}
                            onChange={handleChange(`panel${index}`)}
                            sx={{
                                backgroundColor: 'transparent',
                                boxShadow: 'none',
                                '&:before': {
                                    display: 'none',
                                },
                                mb: 1,
                            }}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                sx={{
                                    minHeight: '48px',
                                    '& .MuiAccordionSummary-content': {
                                        margin: '8px 0',
                                    },
                                    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                                        transform: 'rotate(180deg)',
                                    },
                                    '& .MuiAccordionSummary-content': {
                                        marginLeft: 2,
                                    },
                                }}
                            >
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <MobileFramedImage src={`/${problem.icon}`} alt={problem.title} />
                                    <Typography sx={{ ml: 2, variant: "body0", fontWeight: "bold" }}>{problem.title}</Typography>
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails sx={{ pt: 0, pb: 1 }}>
                                <Fade in={expanded === `panel${index}`}>
                                    <Typography variant="body2" color="text.secondary">
                                        {problem.text}
                                    </Typography>
                                </Fade>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </Box>
            </Box>
        </Box>
    );
};

export default WebProblemsGridMobile;
