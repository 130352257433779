import React, { useState, useEffect } from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Paper, IconButton, CircularProgress, styled
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { FiberManualRecord as StatusIcon, Add as AddIcon } from '@mui/icons-material';

import { Button, TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Skeleton from '@mui/material/Skeleton';
// import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import useMediaQuery from '@mui/material/useMediaQuery';
import SmallShinyDemo from './SmallShinyDemo';

// Define a single variable for the font size
const tableFontSize = '14px';

// Styled components
const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
    boxShadow: 'none',
    width: '100%',
    marginBottom: theme.spacing(1),
    background: 'transparent',
    overflowX: 'auto', // Add this line to enable horizontal scrolling
}));

const StyledTable = styled(Table)({
    borderCollapse: 'separate',
    borderSpacing: 0,
    width: '100%',
});

const StyledTableCell = styled(TableCell)(({ theme, isLastColumn, isSecondLastColumn, isUrlColumn, isScoreColumn, isCategoryColumn }) => ({
    borderBottom: `1px solid ${theme.palette.divider}`,
    borderRight: isLastColumn || isSecondLastColumn ? 'none' : `1px solid ${theme.palette.divider}`,
    padding: '4px 8px',
    fontSize: tableFontSize,
    verticalAlign: 'middle',
    textAlign: 'left',
    height: '32px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '0',
    minWidth: isLastColumn ? '50px' : 'auto',
    ...(isLastColumn && {
        borderBottom: 'none',
        backgroundColor: 'transparent',
        overflow: 'visible',
        textOverflow: 'clip',
    }),
    ...(isUrlColumn && {
        backgroundColor: 'rgba(0, 0, 0, 0.03)', // Light gray background for URL column
    }),
    ...(isScoreColumn && {
        overflow: 'visible',
        textOverflow: 'clip',
    }),
    ...(isCategoryColumn && {
        overflow: 'visible',
        textOverflow: 'clip',
    }),
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:last-child td, &:last-child th': {
        borderBottom: 'none',
    },
    height: '32px', // Reduced fixed height for the row
}));

const StyledTableHead = styled(TableHead)(({ theme }) => ({
    '& th': {
        backgroundColor: 'transparent',
        fontWeight: 'bold',
    },
    '& th:first-of-type': {
        borderTopLeftRadius: theme.shape.borderRadius,
    },
    '& th:last-child': {
        borderTopRightRadius: theme.shape.borderRadius,
    },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
    '& .MuiInput-underline:before': {
        borderBottom: 'none',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottom: 'none',
    },
    '& .MuiInput-underline:after': {
        borderBottom: 'none',
    },
    '& .MuiInputBase-input': {
        fontSize: tableFontSize,
        paddingLeft: 0,
    },
}));

const StyledButton = styled(Button)(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
    textTransform: 'none',
    fontSize: tableFontSize,
}));

const StyledHeaderCell = styled(StyledTableCell)(({ theme, isLastColumn, isSecondLastColumn }) => ({
    borderBottom: `1px solid ${theme.palette.divider}`,
    borderTop: 'none',
    ...(isLastColumn && {
        borderBottom: 'none',
        borderRight: 'none',
    }),
    ...(isSecondLastColumn && {
        borderRight: 'none',
    }),
}));

const StyledHeaderRow = styled(TableRow)(({ theme }) => ({
    borderBottom: `2px solid ${theme.palette.divider}`,
}));

// Simulated backend function
const simulateBackend = (url) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve({
                title: `Title for ${url}`,
                description: `This is a simulated description for ${url}.`
            });
        }, 2000);
    });
};

const RelevanceMarker = styled('span')(({ theme, relevance }) => ({
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '60px',
    height: '24px',
    borderRadius: '16px',
    fontSize: '14px',
    fontWeight: 'bold',
    color: 'white',
    backgroundColor: (() => {
        // Define colors for 7 and 10
        const gray = [242, 225, 141];  // RGB for gray
        const green = [92, 199, 39];   // RGB for green

        // If relevance is below 7, return gray
        if (relevance < 7) {
            return `rgb(${gray.join(', ')})`;
        }

        // Calculate the interpolation factor
        const t = (relevance - 7) / 3;  // This will be 0 at 7 and 1 at 10

        // Interpolate between gray and green
        const interpolatedColor = gray.map((start, index) => {
            const end = green[index];
            return Math.round(start + (end - start) * t);
        });

        return `rgb(${interpolatedColor.join(', ')})`;
    })(),
    whiteSpace: 'nowrap',
    overflow: 'visible',
    [theme.breakpoints.down('lg')]: {
        width: '45px',
        height: '22px',
        fontSize: '13px',
    },
    [theme.breakpoints.down('md')]: {
        width: '40px',
        height: '20px',
        fontSize: '12px',
    },
    [theme.breakpoints.down('sm')]: {
        width: '35px',
        height: '18px',
        fontSize: '11px',
    },
}));

const CategoryMarker = styled('span')(({ theme, category }) => {
    const categoryInfo = {
        1: { name: 'conference', color: '#88BBC9' },
        2: { name: 'event', color: '#B6C4A8' },
        3: { name: 'lecture', color: '#CBB2E0' },
        4: { name: 'program', color: '#F6AFA8' },
        5: { name: 'workshop', color: '#F3C689' },
    };

    const info = categoryInfo[category] || { name: 'Unknown', color: '#808080' };

    return {
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0 8px',
        height: '24px',
        borderRadius: '16px',
        fontSize: '14px',
        fontWeight: 'bold',
        color: 'white',
        backgroundColor: info.color,
        whiteSpace: 'nowrap',
        overflow: 'visible', // Changed from 'hidden' to 'visible'
    };
});

const InteractiveUrlTable = () => {
    const predefinedData = [
        { url: 'https://swissnex.org/event/biotechx-europe/', title: 'BioTechX Europe', description: 'Leading European event for diagnostics, precision medicine, and pharma innovation.', relevance: 9.42, category: 1 },
        { url: 'https://dg.philhist.unibas.ch/de/aktuelles/news/details/the-planetary-turn-in-human-history/', title: 'The Planetary Turn in Human History', description: 'Lecture exploring dual narratives of human history: postcolonialism and climate change.', relevance: 5.72, category: 5 },
        { url: 'https://blab-switzerland.ch/swiss-impact-forum-2024/', title: 'Swiss Impact Forum', description: 'Annual forum dedicated to the topics of renewable economy and systemic change.', relevance: 8.93, category: 1 },
        { url: 'https://www.startup-nights.ch/program', title: 'Startup Nights', description: 'Event offering 60+ sessions and networking with 120 innovative startups.', relevance: 7.89, category: 2 },
        { url: 'https://www.venturelab.swiss/index.cfm?CFID=264062141&CFTOKEN=40b57856ba21b854-AD2C9336-04C3-A8A9-2E307F9A7DAC6661&page=138854&event_id=10898', title: 'ESA BIC Switzerland', description: 'Incubator providing funding and support for startups with space-related innovations.', relevance: 8.22, category: 4 },
        { url: 'https://startup-academy.ch/events/29062/', title: 'Startup Tool Box', description: 'Workshop covers essential tools and phases for successfully launching a startup.', relevance: 8.03, category: 5 },
        { url: 'https://www.startupticker.ch/en/events/deadline-ait-blockchain-and-ai', title: 'AIT Blockchain and AI', description: 'Program connecting Swiss Blockchain and AI innovators with African ecosystems.', relevance: 6.36, category: 4 },
        { url: 'https://www.eventbrite.ch/e/venture-2025-kickoff-zurich-tickets-1012602410067?aff=homepage', title: 'Venture Pitch', description: 'Event connects aspiring entrepreneurs with mentors, funding, and startup competition opportunities.', relevance: 9.35, category: 2 },
        { url: 'https://home.jointcreate.com/en_us/events/178/?organizationId=79', title: 'Circular Building UnConference 2024', description: 'Conference showcasing sustainable building practices to reduce emissions and waste.', relevance: 4.76, category: 1 },
        { url: 'https://swissnex.org/china/event/cosmos-archaeology-宇宙考古/', title: 'Cosmos Archaeology', description: 'Immersive exhibition explores astrophysics through art and interactive installations.', relevance: 4.10, category: 3 },
    ];

    const [rows, setRows] = useState(predefinedData.map((data, index) => {
        if (index < 2) {
            return {
                id: index + 1,
                ...data,
                status: 'completed'
            };
        } else {
            return {
                id: index + 1,
                url: data.url,
                title: '',
                description: '',
                relevance: 0,
                category: 0,
                status: 'ready'
            };
        }
    }));

    const [lastCompletedRowId, setLastCompletedRowId] = useState(2);

    const [buttonVisibility, setButtonVisibility] = useState({
        3: true,
        4: false,
        5: false,
    });

    useEffect(() => {
        const loadRows = async () => {

            await new Promise(resolve => setTimeout(() => {
                analyzeUrl(3);
                resolve();
            }, 500));
            for (let i = 4; i <= 5; i++) {
                await new Promise(resolve => setTimeout(() => {
                    analyzeUrl(i);
                    resolve();
                }, 2500));
            }
        };

        loadRows();
    }, []);

    useEffect(() => {
        if (lastCompletedRowId >= 2) {
            // Hide the previous button
            setButtonVisibility(prev => ({ ...prev, [lastCompletedRowId]: false }));

            // Show the next button after a delay
            setTimeout(() => {
                setButtonVisibility(prev => ({ ...prev, [lastCompletedRowId + 1]: true }));
            }, 500); // 500ms delay, matching the transition duration
        }
    }, [lastCompletedRowId]);

    const analyzeUrl = (id) => {
        setRows(prevRows =>
            prevRows.map(row =>
                row.id === id ? { ...row, status: 'loading' } : row
            )
        );

        const delay = 1000 + Math.random() * 1000;
        setTimeout(() => {
            setRows(prevRows =>
                prevRows.map(row =>
                    row.id === id ? {
                        ...row,
                        ...predefinedData[id - 1],
                        status: 'completed'
                    } : row
                )
            );
            setLastCompletedRowId(id);
        }, delay);
    };

    const getNextUnfilledRowId = () => {
        return rows.find(row => row.id > lastCompletedRowId && row.status === 'ready')?.id;
    };

    const theme = useTheme();

    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <div style={{ width: '100%' }}>
            {isSmallScreen ? (
                <SmallShinyDemo
                    rows={rows.slice(0, 6)}
                />
            ) : (
                    <StyledTableContainer component={Paper} style={{ width: '100%', backgroundColor: 'transparent' }}>
                        <StyledTable style={{ width: '100%', tableLayout: 'fixed' }} size="small">
                            <StyledTableHead>
                                <StyledHeaderRow>
                                    <StyledHeaderCell style={{ width: '20%' }} isUrlColumn>URL</StyledHeaderCell>
                                    <StyledHeaderCell style={{ width: '7%' }} isScoreColumn>Score</StyledHeaderCell>
                                    <StyledHeaderCell style={{ width: '25%' }}>Title</StyledHeaderCell>
                                    <StyledHeaderCell style={{ width: '30%' }}>Description</StyledHeaderCell>
                                    <StyledHeaderCell style={{ width: '10%' }} isSecondLastColumn isCategoryColumn>Category</StyledHeaderCell>
                                    <StyledHeaderCell style={{ width: '50px' }} isLastColumn></StyledHeaderCell>
                                </StyledHeaderRow>
                            </StyledTableHead>
                            <TableBody>
                                {rows.map((row) => (
                                    <StyledTableRow key={row.id} style={{ backgroundColor: 'transparent' }}>
                                        <Tooltip title={row.url} placement="top">
                                            <StyledTableCell isUrlColumn>{row.url}</StyledTableCell>
                                        </Tooltip>
                                        <StyledTableCell isScoreColumn>
                                            {row.status === 'loading' ? (
                                                <Skeleton animation="wave" variant="rectangular" width={40} height={24} style={{ borderRadius: '16px' }} />
                                            ) : row.status === 'completed' ? (
                                                <RelevanceMarker relevance={row.relevance}>
                                                    {row.relevance.toFixed(1)}
                                                </RelevanceMarker>
                                            ) : null}
                                        </StyledTableCell>
                                        <Tooltip title={row.status === 'completed' ? row.title : ''} placement="top">
                                            <StyledTableCell>
                                                {row.status === 'loading' ? (
                                                    <Skeleton animation="wave" height={20} width="80%" />
                                                ) : row.status === 'completed' ? (
                                                    row.title
                                                ) : (
                                                    ''
                                                )}
                                            </StyledTableCell>
                                        </Tooltip>
                                        <Tooltip title={row.status === 'completed' ? row.description : ''} placement="top">
                                            <StyledTableCell>
                                                {row.status === 'loading' ? (
                                                    <Skeleton animation="wave" height={20} width="100%" />
                                                ) : row.status === 'completed' ? (
                                                    row.description
                                                ) : (
                                                    ''
                                                )}
                                            </StyledTableCell>
                                        </Tooltip>
                                        <StyledTableCell isSecondLastColumn isCategoryColumn>
                                            {row.status === 'loading' ? (
                                                <Skeleton animation="wave" variant="rectangular" width={60} height={24} style={{ borderRadius: '16px' }} />
                                            ) : row.status === 'completed' ? (
                                                <CategoryMarker category={row.category}>
                                                        {['conference', 'event', 'conference', 'program', 'workshop'][row.category - 1]}
                                                </CategoryMarker>
                                            ) : null}
                                        </StyledTableCell>
                                        <StyledTableCell isLastColumn>
                                            <IconButton
                                                onClick={() => analyzeUrl(row.id)}
                                                disabled={row.status === 'loading' || row.status === 'completed'}
                                                size="small"
                                                sx={{
                                                    backgroundColor: "#c038c2",
                                                    color: theme.palette.primary.contrastText,
                                                    '&:hover': {
                                                        backgroundColor: theme.palette.primary.dark,
                                                        boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
                                                    },
                                                    '&:active': {
                                                        boxShadow: '0 1px 2px rgba(0,0,0,0.2)',
                                                    },
                                                    '&:disabled': {
                                                        backgroundColor: 'transparent',
                                                        color: '#757575',
                                                        boxShadow: 'none',
                                                    },
                                                    padding: '4px',
                                                    minWidth: '28px',
                                                    height: '28px',
                                                    borderRadius: '50%',
                                                    opacity: buttonVisibility[row.id] ? 1 : 0,
                                                    visibility: buttonVisibility[row.id] ? 'visible' : 'hidden',
                                                    transition: 'opacity 0.5s ease-in-out, visibility 0.5s ease-in-out, box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out',
                                                    boxShadow: 'none',
                                                }}
                                            >
                                                <ArrowForwardIcon fontSize="small" />
                                            </IconButton>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </StyledTable>
                    </StyledTableContainer>
            )}
        </div>
    );
};

export default InteractiveUrlTable;
