import React from 'react';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import WebProblemsGrid from './WebProblemsGrid';
import WebProblemsGridMobile from './WebProblemsGridMobile';

const ResponsiveWebProblemsGrid = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return isMobile ? <WebProblemsGridMobile /> : <WebProblemsGrid />;
};

export default ResponsiveWebProblemsGrid;
