import React, { useState, useEffect, useCallback } from 'react';
import { Button, Typography } from '@mui/material';
import { styled } from '@mui/system';


// ["DONE", "ERROR", "EXTRACTING", "EXTRACTED", "PROCESSING", "PROCESSED", "unknown"]

const StatusButton = styled(Button)(({ theme, status }) => ({
    padding: theme.spacing(1, 2),
    height: '32px',
    minWidth: 'auto',
    borderRadius: '15px',
    textTransform: 'none',
    backgroundColor: (() => {
        switch (status) {
            case 'DONE':
                return theme.palette.status.success.main;
            case null:
            case undefined:
            case 'unknown':
            case 'SENDING':
                return theme.palette.status.custom.black;
            case 'ERROR':
                return theme.palette.status.error.main;
            case 'EXTRACTING':
            case 'EXTRACTED':
                return theme.palette.status.custom.extracting;
            case 'PROCESSING':
            case 'PROCESSED':
                return theme.palette.status.custom.processing;
            default:
                return theme.palette.status.grey[300];
        }
    })(),
    '&:hover': {
        backgroundColor: (() => {
            switch (status) {
                case 'DONE':
                    return theme.palette.status.success.dark;
                case null:
                case undefined:
                case 'unknown':
                case 'SENDING':
                    return theme.palette.status.custom.darkBlack;
                case 'ERROR':
                    return theme.palette.status.error.dark;
                case 'EXTRACTING':
                case 'EXTRACTED':
                    return theme.palette.status.custom.darkExtracting;
                case 'PROCESSING':
                case 'PROCESSED':
                    return theme.palette.status.custom.darkProcessing;
                default:
                    return theme.palette.status.grey[400];
            }
        })(),
    },
    color: theme.palette.getContrastText(theme.palette.success.main),
}));

const StatusDot = styled('span')(({ theme }) => ({
    width: 8,
    height: 8,
    borderRadius: '50%',
    marginRight: theme.spacing(1),
    backgroundColor: 'currentColor',
}));

const StatusIndicator = ({ status }) => {

    const getDisplayStatus = () => {
        switch (status) {
            case 'SENDING':
                return 'Sending';
            case null:
            case undefined:
            case 'unknown':
                return 'Ready';
            case 'ERROR':
                return 'Error';
            case 'EXTRACTING':
            case 'EXTRACTED':
                return 'Analyzing';
            case 'PROCESSING':
            case 'PROCESSED':
                return 'Processing';
            case 'DONE':
                return 'Done';
            default:
                return 'Unknown';
        }
    };

    return (
        <StatusButton variant="contained" status={status} disableElevation>
            <StatusDot />
            <Typography variant="body2" component="span" sx={{ fontWeight: "bold" }}>
                {getDisplayStatus()}
            </Typography>
        </StatusButton>
    );
};

export default StatusIndicator;