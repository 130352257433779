import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Slide from "@mui/material/Slide";
import theme from "../../theme";
import { Button } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import BetaModal from "../BetaModal";

const HideOnScroll = ({ children }) => {
    const trigger = useScrollTrigger();

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
};

const Header = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <HideOnScroll>
                <AppBar
                    position="fixed"
                    sx={{
                        background: "transparent",
                        boxShadow: "none",
                        "&::before": {
                            content: '""',
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            background: "rgba(255, 255, 255, 0.3)",
                            backdropFilter: "blur(10px)",
                            mask: "linear-gradient(to bottom, black 50%, transparent 100%)",
                            WebkitMask:
                                "linear-gradient(to bottom, black 50%, transparent 100%)",
                        },
                    }}
                >
                    <Container maxWidth="lg">
                        <Toolbar disableGutters>
                            <Box display="flex" alignItems="center">
                                <Link
                                    href="/"
                                    style={{
                                        color: "inherit",
                                        textDecoration: "none",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <img
                                            src={`${process.env.PUBLIC_URL}/logo.png`}
                                            alt="Shoco Logo"
                                            style={{ height: "40px" }}
                                        />
                                    </div>
                                </Link>
                            </Box>
                            <Box sx={{ flexGrow: 1 }} />
                            <Typography variant="header" component="div">
                                <nav>
                                    <ThemeProvider theme={theme}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => setIsModalOpen(true)}
                                        >
                                            <Typography
                                                variant="header"
                                                sx={{ fontWeight: "bold" }}
                                            >
                                                Join beta
                                            </Typography>
                                        </Button>
                                        <BetaModal
                                            open={isModalOpen}
                                            onClose={() =>
                                                setIsModalOpen(false)
                                            }
                                        />
                                    </ThemeProvider>
                                </nav>
                            </Typography>
                        </Toolbar>
                    </Container>
                </AppBar>
            </HideOnScroll>
        </ThemeProvider>
    );
};

export default Header;
