import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import FeatList from './FeatList';
import FeatListMobile from './FeatListMobile';

const ResponsiveFeatList = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return isMobile ? <FeatListMobile /> : <FeatList />;
};

export default ResponsiveFeatList;