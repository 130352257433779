import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import CssBaseline from '@mui/material/CssBaseline';
import theme from '../theme';
import { Typography, Box, Link, Card, CardContent, } from '@mui/material';
import Button from '@mui/material/Button';
import ProductDemo from './demo/ProductDemo';
import InteractiveUrlTable from './demo/ShinyDemo';
import { IoBulbOutline, IoLayersOutline, IoTrendingUpOutline, IoCheckmarkCircleOutline } from 'react-icons/io5';
import ResponsiveWebProblemsGrid from './webproblems/ResponsiveWebProblemsGrid';

import ResponsiveFeatList from './featureslist/ResponseFeatList';
import SupportedBy from './SupportedBy';

const CardStyle = {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    backdropFilter: 'blur(10px)',
    borderRadius: '16px',
    boxShadow: '0 8px 12px 0 rgba(31, 38, 135, 0.37)',
    border: '1px solid rgba(255, 255, 255, 0.18)',
    overflow: 'hidden',
    position: 'relative',
    '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: -1,
        background: 'inherit',
        filter: 'blur(10px)',
        borderRadius: 'inherit',
    },
};


function App() {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Container>


                <Box
                    id="top"
                    sx={{
                        height: { xs: '50vh', md: '65vh' },
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                    }}
                >
                    <Typography variant="title" sx={{ fontWeight: 1000, width: { xs: "100%", md: '70%' } }}>
                        untangle the web
                    </Typography>
                    <Box sx={{ height: '20px' }}></Box>
                    <Typography variant="tagline" sx={{ width: { xs: "80%", md: '60%' } }}>
                        Intelligent end-to-end web data extraction. Just like{' '}
                        <Link
                            href="#demo"
                            sx={{
                                fontStyle: 'italic',
                                fontWeight: 'bold',
                                textDecoration: 'underline',
                                color: 'black',
                                transition: 'filter 0.3s ease-in-out',
                                '&:hover': {
                                    color: 'rgb(255, 196, 0)'
                                },
                            }}
                        >
                            that
                        </Link>
                        .
                    </Typography>
                </Box>
                <Card
                    id="sheet"
                    sx={{
                        backgroundColor: 'rgba(255, 255, 255, 1)',
                        height: { xs: 'auto' },//, md: '70vh' },
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        borderRadius: '32px',
                        boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
                        border: '1px solid rgba(255, 255, 255, 0.18)',
                        overflow: 'auto', // Add this to enable scrolling if content exceeds card height
                        '&::before': {
                            content: '""',
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 0,
                            zIndex: -1,
                            background: 'inherit',
                            filter: 'blur(10px)',
                            borderRadius: 'inherit',
                        },
                    }}
                >
                    <Box sx={{ padding: '16px' }}>
                        <InteractiveUrlTable />
                    </Box>
                </Card>

                <Box sx={{ minHeight: "20vh" }} />

                {/* Problems Section */}
                <ResponsiveWebProblemsGrid />

                {/* Add vertical space */}
                <Box sx={{ height: { xs: '10vh', md: '20vh' } }} />

                {/* Replace the Features Section with FeatList component */}

                <ResponsiveFeatList />

                {/* Add vertical space and demo anchor */}
                <Box id="demo" sx={{ height: { xs: '10vh', md: '20vh' } }} />

                {/* Product Demo Section */}
                <Box sx={{
                    minHeight: { xs: 'auto', md: '70vh' },
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    mb: 4,
                    scrollMarginTop: '15vh',
                }}>
                    <ProductDemo />
                </Box>

                {/* SupportedBy Section */}
                <SupportedBy />

            </Container>
        </ThemeProvider>
    );
}

export default App;
