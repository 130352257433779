import React, { useState } from 'react';
import { Typography, Box, Card, CardContent, Grid, Button } from '@mui/material';
import { IoBulbOutline, IoLayersOutline, IoTrendingUpOutline, IoCheckmarkCircleOutline } from 'react-icons/io5';
import BetaModal from '../../components/BetaModal';

const CardStyle = {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    backdropFilter: 'blur(10px)',
    borderRadius: '16px',
    boxShadow: '0 8px 12px 0 rgba(31, 38, 135, 0.37)',
    border: '1px solid rgba(255, 255, 255, 0.18)',
    overflow: 'hidden',
    position: 'relative',
    '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: -1,
        background: 'inherit',
        filter: 'blur(10px)',
        borderRadius: 'inherit',
    },
};

const FeatureItem = ({ icon, title, text }) => (
    <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    }}>
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            mb: 2,
        }}>
            <Box sx={{
                mb: 2,
                width: 96,
                height: 96,
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                '&::before, &::after, &>span::before, &>span::after': {
                    content: '""',
                    position: 'absolute',
                    width: 12,
                    height: 12,
                    borderColor: 'black',
                    borderStyle: 'solid',
                },
                '&::before': { top: 0, left: 0, borderWidth: '2px 0 0 2px' },
                '&::after': { top: 0, right: 0, borderWidth: '2px 2px 0 0' },
                '&>span::before': { bottom: 0, left: 0, borderWidth: '0 0 2px 2px' },
                '&>span::after': { bottom: 0, right: 0, borderWidth: '0 2px 2px 0' },
            }}>
                <span style={{ position: 'absolute', width: '100%', height: '100%' }}></span>
                {icon}
            </Box>
        </Box>
        <Box sx={{ height: '5px' }}></Box>
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
            <Typography variant="body0" sx={{
                fontWeight: "bold",
                mb: 1,
                textAlign: 'left'
            }}>
                {title}
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ textAlign: 'left' }}>
                {text}
            </Typography>
        </Box>
    </Box>
);

const FeatList = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <Box
            id="product"
            sx={{
                minHeight: "100vh",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                py: { xs: 8, md: 4 },
            }}
        >
            <Grid
                container
                direction="column"
                spacing={2}
                sx={{
                    width: "100%",
                    margin: "0 auto",
                    alignItems: "center",
                }}
            >
                {/* Centered spreadsheet card */}
                <Grid
                    item
                    xs={12}
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        width: "75%",
                        position: "relative",
                    }}
                >
                    <Card
                        sx={{
                            ...CardStyle,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "stretch",
                            justifyContent: "flex-start",
                            backgroundColor: "rgba(255, 255, 255, 0.9)",
                            width: "80%",
                            position: "relative",
                            m: 2,
                        }}
                    >
                        <CardContent sx={{ p: 0 }}>
                            <Box sx={{ position: "relative" }}>
                                <Box
                                    component="img"
                                    src="./spreadsheet.png"
                                    alt="Spreadsheet"
                                    sx={{
                                        display: "block",
                                        width: "100%",
                                        height: "auto",
                                    }}
                                />
                                <Box
                                    sx={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        backgroundColor:
                                            "rgba(255, 255, 255, 0.1)",
                                    }}
                                />
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>

                {/* Centered features grid */}
                <Grid
                    item
                    xs={12}
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        width: "90%",
                        mt: -20,
                    }}
                >
                    <Card
                        sx={{
                            ...CardStyle,
                            width: "100%",
                            backgroundColor: "rgba(255, 255, 255, 1)",
                            pt: 4,
                            pb: 6,
                            pl: 4,
                            pr: 4,
                            borderRadius: "32px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            boxShadow: "0 16px 32px 0 rgba(31, 38, 135, 0.37)",
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",
                                mb: 1,
                            }}
                        >
                            <Typography
                                variant="subtitle1"
                                sx={{ fontWeight: "bold", mb: 2 }}
                            >
                                Turn the web into your database
                            </Typography>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => setIsModalOpen(true)}
                                sx={{ px: 4 }}
                            >
                                <Typography
                                    variant="subtitle2"
                                    sx={{ fontWeight: "bold" }}
                                >
                                    Join beta
                                </Typography>
                            </Button>
                            <BetaModal
                                open={isModalOpen}
                                onClose={() => setIsModalOpen(false)}
                            />
                        </Box>
                        <Grid container spacing={4} sx={{ height: "100%" }}>
                            {[
                                {
                                    icon: <IoBulbOutline size={36} />,
                                    title: "Adaptable",
                                    text: "Supported by advances in language models, shoco's vertically integrated approach outpaces conventional tools.",
                                },
                                {
                                    icon: <IoLayersOutline size={36} />,
                                    title: "Robust",
                                    text: "Frequent changes to the web don't affect shoco. It automatically adapts to new structures.",
                                },
                                {
                                    icon: <IoTrendingUpOutline size={36} />,
                                    title: "Scalable",
                                    text: "An optimized tech-stack enables large-scale data collection for the smallest budget.",
                                },
                                {
                                    icon: (
                                        <IoCheckmarkCircleOutline size={36} />
                                    ),
                                    title: "Performant",
                                    text: "Cutting edge tech, but no need to worry about it. Shoco needs no maintenance and delivers on time.",
                                },
                            ].map((feature, index) => (
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    md={3}
                                    key={index}
                                    sx={{ display: "flex" }}
                                >
                                    <FeatureItem
                                        icon={feature.icon}
                                        title={feature.title}
                                        text={feature.text}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
};

export default FeatList;

