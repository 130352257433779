import React from 'react';
import { Typography, Box, Grid } from '@mui/material';

const SupportedBy = () => {
    return (
        <Box id="support" sx={{
            height: { xs: "auto", md: '50vh' },
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            py: { xs: 4, md: 8 },
            maxWidth: "100%",
            mx: "auto",
        }}>
            <Typography variant="subtitle1" sx={{ mb: { xs: 2, md: 8 } }}>
                supported by
            </Typography>
            <Grid container sx={{
                spacing: { xs: 2, md: 16 },
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                {['ai-center-logo.png', 'talentkick-logo.png'].map((logo, index) => (
                    <Grid item xs={6} md={5} key={index}>
                        <Box
                            sx={{
                                borderRadius: 1,
                                padding: { xs: 1, md: 2 },
                                textAlign: 'center',
                                width: { xs: '100%', md: '70%' },
                                mx: 'auto',
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <a href={index === 0 ? "https://ai.ethz.ch" : "https://talentkick.ch"} target="_blank" rel="noopener noreferrer">
                                <Box
                                    component="img"
                                    src={`./${logo}`}
                                    alt={index === 0 ? "ETH AI Center" : "Talentkick"}
                                    sx={{
                                        maxWidth: '100%',
                                        maxHeight: '100%',
                                        width: 'auto',
                                        height: 'auto',
                                        filter: {
                                            xs: 'none',
                                            md: 'grayscale(100%)',
                                        },
                                        transition: 'filter 0.1s ease',
                                        '&:hover': {
                                            filter: 'grayscale(0%)',
                                        },
                                        display: 'block',
                                        mx: 'auto',
                                    }}
                                />
                            </a>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default SupportedBy;
