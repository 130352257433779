import React from "react";
import {
    Dialog,
    DialogContent,
    Typography,
    Box,
    IconButton,
    Button,
    useTheme,
    Link,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Link as RouterLink } from "react-router-dom";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const OptionButton = ({ icon, title, description, onClick }) => {

    return (
        <Button
            variant="outlined"
            onClick={onClick}
            sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "16px",
                marginBottom: "16px",
                borderRadius: "15px",
                borderColor: "#000000",
                borderWidth: "1.5px",
                textTransform: "none",
                color: "#000000",
                "&:hover": {
                    borderColor: "#000000",
                    borderWidth: "1.5px",
                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                    "& .arrow-icon": {
                        transform: "translateX(4px)",
                    },
                },
            }}
        >
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                {icon}
                <Box sx={{ textAlign: "left" }}>
                    <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        {title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {description}
                    </Typography>
                </Box>
            </Box>
            <ArrowForwardIcon
                className="arrow-icon"
                sx={{ transition: "transform 0.2s" }}
            />
        </Button>
    );
};

const BetaModal = ({ open, onClose }) => {
    const theme = useTheme();

    const handleTypeformClick = () => {
        window.open("https://ickd5odxdxp.typeform.com/to/PFKQNcrM", "_blank");
    };

    const handleCalendlyClick = () => {
        window.open(
            "https://calendly.com/d/ckq4-fjt-9vs/introduction-to-shoco",
            "_blank"
        );
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="sm"
            fullWidth
            PaperProps={{
                sx: {
                    borderRadius: "32px",
                    padding: "24px",
                    maxWidth: "450px",
                    margin: "16px",
                },
            }}
        >
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: "absolute",
                    right: 16,
                    top: 16,
                }}
            >
                <CloseIcon />
            </IconButton>

            <DialogContent sx={{ p: 2 }}>
                <Typography
                    variant="h5"
                    sx={{
                        textAlign: "center",
                        fontWeight: "bold",
                        mb: 1,
                    }}
                >
                    Join the Beta
                </Typography>

                <Typography
                    variant="body1"
                    sx={{
                        textAlign: "center",
                        color: "text.secondary",
                        mb: 4,
                    }}
                >
                    Choose how you'd like to get started with shoco
                </Typography>

                <OptionButton
                    icon={<PersonOutlineOutlinedIcon />}
                    title="Request Access"
                    description="Fill out a short form to join the waitlist"
                    onClick={handleTypeformClick}
                />

                <OptionButton
                    icon={<CalendarTodayOutlinedIcon />}
                    title="Talk to us directly"
                    description="Schedule a personal demo call"
                    onClick={handleCalendlyClick}
                />

                <Typography
                    variant="caption"
                    sx={{
                        display: "block",
                        textAlign: "center",
                        color: "text.secondary",
                        mt: 2,
                    }}
                >
                    By continuing, you agree to our{" "}
                    <Link
                        component={RouterLink}
                        to="/terms"
                        onClick={onClose}
                        sx={{
                            color: "inherit",
                            textDecoration: "underline",
                            "&:hover": {
                                color: theme.palette.primary.main,
                            },
                        }}
                    >
                        Terms of Service
                    </Link>{" "}
                    and{" "}
                    <Link
                        component={RouterLink}
                        to="/privacy"
                        onClick={onClose}
                        sx={{
                            color: "inherit",
                            textDecoration: "underline",
                            "&:hover": {
                                color: theme.palette.primary.main,
                            },
                        }}
                    >
                        Privacy Policy
                    </Link>
                </Typography>
            </DialogContent>
        </Dialog>
    );
};

export default BetaModal;
