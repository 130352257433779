import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Container, Grid, Typography, Link, IconButton } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';

const Footer = () => {
    return (
      <Box
          component="footer"
          sx={{
              position: 'relative',
              color: 'black',
              py: 6,
              '&::before': {
                  content: '""',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  background: 'white',
                  mask: 'linear-gradient(to top, black 70%, transparent 100%)',
                  WebkitMask: 'linear-gradient(to top, black 70%, transparent 100%)',
              }
          }}
      >
          <Container maxWidth="md" sx={{ position: 'relative', zIndex: 1 }}>
              <Grid container spacing={4}>
                  <Grid item xs={4} md={4}>
                        <Typography variant="h6" gutterBottom fontWeight="bold">
                          Jump To
                      </Typography>
                      <Box>
                          <Link href="/#top" color="inherit" display="block" sx={{ mb: 1 }}>Top</Link>
                          <Link href="/#product" color="inherit" display="block" sx={{ mb: 1 }}>Product</Link>
                      </Box>
                  </Grid>
                  <Grid item xs={4} md={4}>
                        <Typography variant="h6" gutterBottom fontWeight="bold">
                          Legal
                      </Typography>
                      <Box>
                          <Link component={RouterLink} to="/terms" color="inherit" display="block" sx={{ mb: 1 }}>Terms and Conditions</Link>
                          <Link component={RouterLink} to="/privacy" color="inherit" display="block" sx={{ mb: 1 }}>Privacy Policy</Link>
                      </Box>
                  </Grid>
                  <Grid item xs={4} md={4}>
                        <Typography variant="h6" gutterBottom fontWeight="bold">
                          Contact Us
                      </Typography>
                      <Typography variant="body2">Clausiusstr 16</Typography>
                      <Typography variant="body2">8006 Zurich</Typography>
                      <Typography variant="body2">
                          <a href="mailto:info@shoco.io">
                              info@shoco.io
                          </a>
                      </Typography>
                  </Grid>
              </Grid>
              <Box mt={5}>
                  <Typography variant="body2" align="center">
                      © {new Date().getFullYear()} Shoco. All rights reserved.
                  </Typography>
              </Box>
          </Container>
      </Box>
  );
};

export default Footer;
