import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/common/Header';
import Footer from './components/common/Footer';
import LandingPage from './components/LandingPage';
import TermsPage from './components/TermsPage';
import PolicyPage from './components/PolicyPage';
import theme from './theme';
import { Box, Container } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';

const GradientBackground = ({ children }) => {
  return (
    <Box
      sx={{
        minHeight: '100vh',
        width: '100%',
        background: `
          radial-gradient(circle at 95% 5%, rgba(255, 237, 41, 0.1) 0%, rgba(173, 216, 230, 0) 15%), // yellow
          radial-gradient(circle at 70% 25%, rgba(173, 216, 230, 0.3) 0%, rgba(173, 216, 230, 0) 25%), // blue

          radial-gradient(circle at 20% 35%, rgba(255, 255, 255, 1.0) 0%, rgba(173, 216, 230, 0) 25%), // white
          radial-gradient(circle at 15% 20%, rgba(216, 181, 255, 0.5) 0%, rgba(231, 222, 255, 0) 25%), // purple
          
          
          // radial-gradient(circle at 20% 20%, rgba(135, 66, 240, 0.5) 0%, rgba(231, 222, 255, 0) 25%),
          // radial-gradient(circle at 80% 60%, rgba(173, 216, 230, 0.3) 0%, rgba(173, 216, 230, 0) 50%), // blue
          radial-gradient(circle at 40% 80%, rgba(216, 181, 255, 0.3) 0%, rgba(216, 181, 255, 0) 40%) // purple
        `,
        backgroundColor: 'white',
        position: 'relative',
        '&::before': {
          // content: '""',
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          background: 'inherit',
          backgroundBlendMode: 'multiply',
        },
      }}
    >
      {children}
    </Box>
  );
};

function App() {
  return (
    <ThemeProvider theme={theme}>
    <GradientBackground>
    <Router>
      <div>
            <Header />
        {/* <div style={{ minHeight: '60px', backgroundColor: theme.backgroundColor }}></div> */}
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/terms" element={<TermsPage />} />
          <Route path="/privacy" element={<PolicyPage />} />
        </Routes>
        <Footer />
      </div>
    </Router >
      </GradientBackground>
    </ThemeProvider>
  );
}

export default App;
