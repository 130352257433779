import { Box, Card, CardContent, Grid, Typography } from "@mui/material";


const FramedImage = ({ src, alt, width, height }) => {
    const frameSize = 12; // Size of the corner frame parts
    const frameWidth = 2; // Width of the frame lines
    const horFrameOffset = 24; // Horizontal offset for the frame
    const vertFrameOffset = 32; // Vertical offset for the frame

    return (
        <Box
            sx={{
                width: width + frameSize * 2 + horFrameOffset * 2,
                height: height + frameSize * 2 + vertFrameOffset * 2,
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                '&::before, &::after, &>span::before, &>span::after': {
                    content: '""',
                    position: 'absolute',
                    width: frameSize,
                    height: frameSize,
                    borderColor: 'black',
                    borderStyle: 'solid',
                },
                '&::before': { // Top-left
                    top: 0,
                    left: 0,
                    borderWidth: `${frameWidth}px 0 0 ${frameWidth}px`,
                },
                '&::after': { // Top-right
                    top: 0,
                    right: 0,
                    borderWidth: `${frameWidth}px ${frameWidth}px 0 0`,
                },
                '&>span::before': { // Bottom-left
                    bottom: 0,
                    left: 0,
                    borderWidth: `0 0 ${frameWidth}px ${frameWidth}px`,
                },
                '&>span::after': { // Bottom-right
                    bottom: 0,
                    right: 0,
                    borderWidth: `0 ${frameWidth}px ${frameWidth}px 0`,
                },
            }}
        >
            <span style={{ position: 'absolute', width: '100%', height: '100%' }}></span>
            <img
                src={src}
                alt={alt}
                width={width}
                height={height}
                style={{
                    display: 'block',
                }}
            />
        </Box>
    );
};

const WebProblemsGrid = () => {
    const problems = [
        { icon: "broken.png", title: "Unstable", text: "Pages change their internal structure, making conventional automations fail." },
        { icon: "money.png", title: "Expensive", text: "Conventional tools require constant maintenance and expensive intervention." },
        { icon: "mess.png", title: "Messy", text: "Traditional methods do not understand the meaning behind data being processed." },
        { icon: "puzzle.png", title: "Incomplete", text: "Extracted data is often incomplete or inconsistent and requires manual correction." },
    ];

    return (
        <Box sx={{
            height: "80vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }}
        >
            <Card sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'rgba(255, 255, 255, 1)',
                borderRadius: '32px',
                boxShadow: '0 4px 12px 0 rgba(31, 38, 135, 0.37)',
                px: 4,
                py: 4,
            }}>
                <CardContent sx={{ width: '100%' }}>
                    <Typography variant="subtitle1" sx={{ textAlign: "left" }} fontWeight="bold">
                        Getting data from the web is hard
                    </Typography>
                    <Typography variant="body1" color="text.secondary" sx={{ mb: 4 }}>
                        While the demand for data is skyrocketing, data acquisition costs remain unchanged.
                    </Typography>
                    <Box sx={{ position: 'relative', width: '100%', pt: 4 }}>
                        {/* <Divider sx={{ position: 'absolute', top: 0, bottom: 0, left: '50%', borderColor: 'rgba(0, 0, 0, 0.5)', width: 2, }} orientation="vertical" />
                    <Divider sx={{ position: 'absolute', left: 0, right: 0, top: '50%', borderColor: 'rgba(0, 0, 0, 0.5)', height: 2, }} /> */}
                        <Grid container spacing={4}>
                            {problems.map((problem, index) => (
                                <Grid item xs={6} key={index} sx={{ p: 3 }}>
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}>
                                        <FramedImage src={`/${problem.icon}`} alt={problem.title} width={64} height={64} />
                                        <Box sx={{ ml: 3, flex: 1 }}>
                                            <Typography variant="body0" sx={{ mb: 1, fontWeight: "bold" }}>
                                                {problem.title}
                                            </Typography>
                                            <Typography variant="body1" color="text.secondary">
                                                {problem.text}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                            ))}

                        </Grid>
                    </Box>
                </CardContent>
            </Card>
        </Box>
    );
};

export default WebProblemsGrid;