import React, { useState, useEffect } from 'react';
import {
    Typography, Box, Divider, IconButton, Skeleton, useTheme, useMediaQuery
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { styled } from '@mui/material/styles';

const StyledCard = styled(Box)(({ theme }) => ({
    marginBottom: theme.spacing(2),
    position: 'relative',
    overflow: 'visible',
}));

const StyledCardContent = styled(Box)({
    padding: '12px',
    '&:last-child': {
        paddingBottom: '12px',
    },
});

const ActionButton = styled(IconButton)(({ theme }) => ({
    position: 'absolute',
    right: '10px',
    top: '50%',
    transform: 'translateY(-50%)',
    backgroundColor: "#c038c2",
    color: theme.palette.primary.contrastText,
    '&:hover': {
        backgroundColor: theme.palette.primary.dark,
        boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
    },
    '&:active': {
        boxShadow: '0 1px 2px rgba(0,0,0,0.2)',
    },
    '&:disabled': {
        backgroundColor: 'transparent',
        color: '#757575',
        boxShadow: 'none',
    },
    padding: '6px 16px', // Increased padding
    minWidth: '32px', // Increased minimum width
    height: '36px', // Increased height
    borderRadius: '18px', // Adjusted border radius to match the new height
    transition: 'opacity 0.5s ease-in-out, visibility 0.5s ease-in-out, box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out',
    boxShadow: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'auto',
    padding: '4px 12px',
    borderRadius: '14px',
}));

const RelevanceMarker = styled('span')(({ theme, relevance }) => ({
    padding: '4px 8px',
    borderRadius: '12px',
    fontSize: '0.75rem',
    fontWeight: 'bold',
    backgroundColor: (() => {
        // Define colors for 7 and 10
        const gray = [242, 225, 141];  // RGB for gray
        const green = [92, 199, 39];   // RGB for green

        // If relevance is below 7, return gray
        if (relevance < 7) {
            return `rgb(${gray.join(', ')})`;
        }

        // Calculate the interpolation factor
        const t = (relevance - 7) / 3;  // This will be 0 at 7 and 1 at 10

        // Interpolate between gray and green
        const interpolatedColor = gray.map((start, index) => {
            const end = green[index];
            return Math.round(start + (end - start) * t);
        });

        return `rgb(${interpolatedColor.join(', ')})`;
    })(),
    color: '#000',
    display: 'inline-block',
    minWidth: '32px',
    textAlign: 'center',
}));

const CategoryMarker = styled('span')(({ theme, category }) => {
    const categoryInfo = {
        1: { name: 'conference', color: '#88BBC9' },
        2: { name: 'event', color: '#B6C4A8' },
        3: { name: 'lecture', color: '#CBB2E0' },
        4: { name: 'program', color: '#F6AFA8' },
        5: { name: 'workshop', color: '#F3C689' },
    };

    const info = categoryInfo[category] || { name: 'Unknown', color: '#808080' };

    return {
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0 8px',
        height: '24px',
        borderRadius: '16px',
        fontSize: '14px',
        fontWeight: 'bold',
        color: 'white',
        backgroundColor: info.color,
        whiteSpace: 'nowrap',
        overflow: 'visible',
    };
});

const RowContainer = styled(Box)(({ theme }) => ({
    padding: theme.spacing(2),
    position: 'relative',
}));

const TruncatedTypography = styled(Typography)({
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
});

const UrlContainer = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    flexGrow: 1,
    minWidth: 0,
});

const UrlTypography = styled(Typography)({
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 'bold',
    fontSize: '1rem', // Slightly larger font size
});

const ActionText = styled(Typography)({
    fontWeight: 'bold',
    textTransform: 'none',
    fontSize: '0.75rem',
});

const SmallShinyDemo = () => {
    const predefinedData = [
        { id: 1, url: 'swissnex.org/event/biotechx-europe/', title: 'BioTechX Europe', description: 'Leading European event for diagnostics, precision medicine, and pharma innovation.', relevance: 9.42, category: 1 },
        { id: 2, url: 'dg.philhist.unibas.ch/de/aktuelles/news/details/the-planetary-turn-in-human-history/', title: 'The Planetary Turn in Human History', description: 'Lecture exploring dual narratives of human history: postcolonialism and climate change.', relevance: 5.72, category: 5 },
        { id: 3, url: 'blab-switzerland.ch/swiss-impact-forum-2024/', title: 'Swiss Impact Forum', description: 'Annual forum dedicated to the topics of renewable economy and systemic change.', relevance: 8.93, category: 1 },
        { id: 4, url: 'startup-nights.ch/program', title: 'Startup Nights', description: 'Event offering 60+ sessions and networking with 120 innovative startups.', relevance: 7.89, category: 2 },
        { id: 5, url: 'venturelab.swiss/index.cfm?CFID=264062141&CFTOKEN=40b57856ba21b854-AD2C9336-04C3-A8A9-2E307F9A7DAC6661&page=138854&event_id=10898', title: 'ESA BIC Switzerland', description: 'Incubator providing funding and support for startups with space-related innovations.', relevance: 8.22, category: 4 },
        { id: 6, url: 'startup-academy.ch/events/29062/', title: 'Startup Tool Box', description: 'Workshop covers essential tools and phases for successfully launching a startup.', relevance: 8.03, category: 5 },
    ];

    const [rows, setRows] = useState(predefinedData.map((data, index) => {
        if (index < 2) {
            return {
                ...data,
                status: 'completed'
            };
        } else {
            return {
                id: data.id,
                url: data.url,
                status: 'ready'
            };
        }
    }));

    const [lastCompletedRowId, setLastCompletedRowId] = useState(2);

    const [buttonVisibility, setButtonVisibility] = useState({
        1: false,
        2: true,
        3: false,
        4: false,
        5: false,
        6: false,
    });

    useEffect(() => {
        const loadRows = async () => {
            await new Promise(resolve => setTimeout(() => {
                analyzeUrl(2);
                resolve();
            }, 500));
            for (let i = 3; i <= 3; i++) {
                await new Promise(resolve => setTimeout(() => {
                    analyzeUrl(i);
                    resolve();
                }, 2500));
            }
        };

        loadRows();
    }, []);

    useEffect(() => {
        if (lastCompletedRowId >= 2) {
            setButtonVisibility(prev => ({ ...prev, [lastCompletedRowId]: false }));

            const nextRowId = lastCompletedRowId + 1;
            if (nextRowId <= rows.length) {
                setTimeout(() => {
                    setButtonVisibility(prev => ({ ...prev, [nextRowId]: true }));
                }, 500);
            }
        }
    }, [lastCompletedRowId]);

    const analyzeUrl = (id) => {
        setRows(prevRows =>
            prevRows.map(row =>
                row.id === id ? { ...row, status: 'loading' } : row
            )
        );

        const delay = 1000 + Math.random() * 200;
        setTimeout(() => {
            setRows(prevRows =>
                prevRows.map(row =>
                    row.id === id ? {
                        ...predefinedData[id - 1],
                        status: 'completed'
                    } : row
                )
            );
            setLastCompletedRowId(id);
        }, delay);
    };

    const getNextUnfilledRowId = () => {
        return rows.find(row => row.id > lastCompletedRowId && row.status === 'ready')?.id;
    };

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Box sx={{ width: '100%', margin: '0 auto' }}>
            {rows.map((row, index) => (
                <React.Fragment key={row.id}>
                    <RowContainer>
                        <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                            <UrlContainer>
                                <UrlTypography sx={{ flexShrink: 1, minWidth: 0 }}>
                                    {row.url}
                                </UrlTypography>
                                {row.status === 'completed' && (
                                    <RelevanceMarker relevance={row.relevance} sx={{ flexShrink: 0, ml: 1 }}>
                                        {row.relevance.toFixed(1)}
                                    </RelevanceMarker>
                                )}
                            </UrlContainer>
                            {row.status === 'completed' && (
                                <CategoryMarker category={row.category} sx={{ flexShrink: 0, ml: 2 }}>
                                    {['conference', 'event', 'lecture', 'program', 'workshop'][row.category - 1]}
                                </CategoryMarker>
                            )}
                        </Box>
                        {row.status === 'completed' && (
                            <>
                                <TruncatedTypography variant="body2" gutterBottom>
                                    <strong>Title:</strong> {row.title}
                                </TruncatedTypography>
                                <TruncatedTypography variant="body2">
                                    <strong>Description:</strong> {row.description}
                                </TruncatedTypography>
                            </>
                        )}
                        {row.status === 'loading' && (
                            <>
                                <Skeleton animation="wave" height={24} width="40%" style={{ marginBottom: '8px' }} />
                                <Skeleton animation="wave" height={18} width="100%" />
                                <Skeleton animation="wave" height={18} width="80%" />
                            </>
                        )}
                        {row.status === 'ready' && buttonVisibility[row.id] && (
                            <ActionButton
                                onClick={() => analyzeUrl(row.id)}
                                size="small"
                                sx={{ position: 'absolute', right: '16px', top: '50%', transform: 'translateY(-50%)' }}
                            >
                                <ActionText sx={{ mr: 1 }}>
                                    Run
                                </ActionText>
                                <ArrowForwardIcon fontSize="small" />
                            </ActionButton>
                        )}
                    </RowContainer>
                    {index < rows.length - 1 && <Divider />}
                </React.Fragment>
            ))}
        </Box>
    );
};

export default SmallShinyDemo;
