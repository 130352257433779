import React, { useState } from "react";
import {
    Typography,
    Box,
    Card,
    CardContent,
    Grid,
    Button,
} from "@mui/material";
import {
    IoBulbOutline,
    IoLayersOutline,
    IoTrendingUpOutline,
    IoCheckmarkCircleOutline,
} from "react-icons/io5";
import BetaModal from "../../components/BetaModal";

const CardStyle = {
    backgroundColor: "rgba(255, 255, 255, 0.9)",
    borderRadius: "16px",
    boxShadow: "0 8px 12px 0 rgba(31, 38, 135, 0.37)",
    border: "1px solid rgba(255, 255, 255, 0.18)",
    overflow: "hidden",
};

const FeatureItem = ({ icon, title, text }) => (
    <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
        <Box
            sx={{
                mr: 2,
                width: 64,
                height: 64,
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexShrink: 0,
                "&::before, &::after, &>span::before, &>span::after": {
                    content: '""',
                    position: "absolute",
                    width: 8,
                    height: 8,
                    borderColor: "black",
                    borderStyle: "solid",
                },
                "&::before": { top: 0, left: 0, borderWidth: "2px 0 0 2px" },
                "&::after": { top: 0, right: 0, borderWidth: "2px 2px 0 0" },
                "&>span::before": {
                    bottom: 0,
                    left: 0,
                    borderWidth: "0 0 2px 2px",
                },
                "&>span::after": {
                    bottom: 0,
                    right: 0,
                    borderWidth: "0 2px 2px 0",
                },
            }}
        >
            <span
                style={{ position: "absolute", width: "100%", height: "100%" }}
            ></span>
            {React.cloneElement(icon, { size: 32 })}
        </Box>
        <Box>
            <Typography
                variant="body1"
                sx={{
                    fontWeight: "bold",
                    textAlign: "left",
                    fontSize: "1rem",
                    mb: 0.5,
                }}
            >
                {title}
            </Typography>
            <Typography variant="body2" color="text.secondary">
                {text}
            </Typography>
        </Box>
    </Box>
);

const FeatListMobile = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <Box
            id="product"
            sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                py: 4,
                px: 2,
            }}
        >
            {/* Spreadsheet image */}
            <Card
                sx={{
                    ...CardStyle,
                    width: "100%",
                    mb: 3,
                    p: 0, // Remove padding from the Card
                    overflow: "hidden", // Ensure the image doesn't overflow
                    boxShadow: "none", // Remove shadow from the Card
                }}
            >
                <Box
                    component="img"
                    src="./spreadsheet.png"
                    alt="Spreadsheet"
                    sx={{
                        display: "block",
                        width: "100%",
                        height: "auto",
                        verticalAlign: "bottom", // Remove any potential space below the image
                    }}
                />
            </Card>

            {/* Features section */}
            <Card
                sx={{
                    ...CardStyle,
                    width: "100%",
                    p: 3,
                    borderRadius: "32px",
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <Typography
                    variant="body0"
                    sx={{ fontWeight: "bold", mb: 2, textAlign: "center" }}
                >
                    The web is your database
                </Typography>

                {[
                    {
                        icon: <IoBulbOutline size={24} />,
                        title: "Adaptable",
                        text: "Supported by advances in language models, shoco's approach outpaces conventional tools.",
                    },
                    {
                        icon: <IoLayersOutline size={24} />,
                        title: "Robust",
                        text: "Frequent changes to the web don't affect shoco. It automatically adapts to new structures.",
                    },
                    {
                        icon: <IoTrendingUpOutline size={24} />,
                        title: "Scalable",
                        text: "An optimized tech-stack enables large-scale data collection for the smallest budget.",
                    },
                    {
                        icon: <IoCheckmarkCircleOutline size={24} />,
                        title: "Efficient",
                        text: "shoco's approach is designed to minimize the computational resources required for data collection.",
                    },
                ].map((item, index) => (
                    <FeatureItem key={index} {...item} />
                ))}

                <Box
                    sx={{
                        mt: "auto",
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setIsModalOpen(true)}
                        sx={{ px: 4 }}
                    >
                        <Typography
                            variant="subtitle2"
                            sx={{ fontWeight: "bold" }}
                        >
                            Join beta
                        </Typography>
                    </Button>
                    <BetaModal
                        open={isModalOpen}
                        onClose={() => setIsModalOpen(false)}
                    />
                </Box>
            </Card>
        </Box>
    );
};

export default FeatListMobile;
