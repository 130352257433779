import React, { useState, useEffect } from 'react';
import { Typography, Skeleton, Box } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Card, CardContent } from '@mui/material';
import FeedbackComponent from './FeedbackCard';
import InteractiveUrlTable from './ShinyDemo';
import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}

const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
];

const borderColor = 'rgba(0, 0, 0, 1)';

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
    boxShadow: 'none',
    width: '100%',
    marginBottom: theme.spacing(1),
    background: 'transparent',
    overflowX: 'auto',
    // border: `1px solid ${theme.palette.divider}`,
}));

const StyledTable = styled(Table)({
    borderCollapse: 'separate',
    borderSpacing: 0,
    width: '100%',
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.divider}`,
    borderRight: 'none',
    '&:not(:last-child)': {
        borderRight: `1px solid ${theme.palette.divider}`,
    },
    padding: '8px 12px',
    fontSize: '15px', // Increased from '14px'
    verticalAlign: 'top',
    textAlign: 'left',
    whiteSpace: 'normal',
    wordBreak: 'break-word',
    overflowWrap: 'break-word',
    minWidth: '100px',
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:last-child td, &:last-child th': {
        borderBottom: 'none',
    },
    height: '32px',
}));

const StyledTableHead = styled(TableHead)(({ theme }) => ({
    '& th': {
        backgroundColor: 'transparent',
        fontWeight: 'bold',
        borderBottom: `2px solid ${theme.palette.divider}`,
    },
    '& th:first-of-type': {
        borderTopLeftRadius: 0,
    },
    '& th:last-child': {
        borderTopRightRadius: 0,
    },
}));

const AdaptiveJsonDisplay = ({ status, taskId, response }) => {
    const [errorMsg, setErrorMsg] = useState(null);

    useEffect(() => {
        if (status === "ERROR") {
            if (taskId === null || !response.error) {
                setErrorMsg("There was an issue processing your request. Are you complying with our usage policy?");
            } else {
                setErrorMsg(response.error);
            }
        } else {
            setErrorMsg(null);
        }
    }, [status, taskId, response]);

    if (status === "unknown") {
        return <Box />;
    }

    if (["EXTRACTING", "EXTRACTED", "PROCESSING", "PROCESSED"].includes(status)) {
        return (
            <Box sx={{ m: 2, width: '90%' }}>
                <Skeleton variant="text" width="60%" />
                <Skeleton variant="text" width="80%" />
                <Skeleton variant="text" width="40%" />
            </Box>
        );
    }

    if (status === "ERROR") {
        return (
            <Box sx={{ m: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'left', height: '100%', pl: 2 }}>
                    <Typography variant="body1" color="error" sx={{ fontWeight: "bold" }}>
                        {errorMsg}
                    </Typography>
                </Box>
                {/* <FeedbackComponent taskId={taskId} /> */}
            </Box>
        );
    }

    if (status === "DONE") {
        const fields = Object.keys(response);
        const values = Object.values(response);

        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <StyledTableContainer component={Box}>
                        <StyledTable aria-label="json data table">
                            <StyledTableHead>
                                <StyledTableRow>
                                    {fields.map((field, index) => (
                                        <StyledTableCell key={index}>
                                            <Typography variant="body1" sx={{ fontWeight: "bold" }}>{field}</Typography>
                                        </StyledTableCell>
                                    ))}
                                </StyledTableRow>
                            </StyledTableHead>
                            <TableBody>
                                <StyledTableRow>
                                    {values.map((value, index) => (
                                        <StyledTableCell key={index}>
                                            <Typography variant="body1">
                                                {typeof value === 'object' ? JSON.stringify(value, null, 2) : String(value)}
                                            </Typography>
                                        </StyledTableCell>
                                    ))}
                                </StyledTableRow>
                            </TableBody>
                        </StyledTable>
                    </StyledTableContainer>
                </Grid>
                <Grid item xs={12}>
                    <FeedbackComponent taskId={taskId} />
                </Grid>
            </Grid>
        );
    }

    return null;
};

export default AdaptiveJsonDisplay;
