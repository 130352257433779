import React, { useState } from 'react';
import { Box, Paper, TextField, Button, Typography, Popover } from '@mui/material';
import FeedbackIcon from '@mui/icons-material/Feedback';


const API_URL = 'https://api.shoco.io:27337/v1/demo';
// const API_URL = 'http://localhost:8086/v1/demo';

const FeedbackComponent = ({ taskId }) => {
    const [feedback, setFeedback] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const handleSubmit = async () => {
        if (feedback.trim() === '') {
            setError('Feedback cannot be empty');
            return;
        }

        setIsSubmitting(true);
        setError('');

        try {
            const response = await fetch(`${API_URL}/${taskId}/feedback`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    feedback: feedback,
                }),
            });

            if (!response.ok) {
                console.log(response);
                throw new Error('Failed to submit feedback');
            }

            setFeedback('');
            alert('Feedback submitted successfully!');
        } catch (error) {
            setError('Failed to submit feedback. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <>
            <Button
                variant="contained"
                onClick={handleClick}
                startIcon={<FeedbackIcon />}
                sx={{
                    borderRadius: "20px",
                    padding: "10px 20px",
                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                }}
            >
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    Feedback
                </Typography>
            </Button>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                PaperProps={{
                    sx: {
                        borderRadius: "16px",
                        overflow: "hidden",
                    },
                }}
            >
                <Paper sx={{ width: 300, p: 2, borderRadius: "16px" }}>
                    <Typography
                        variant="h6"
                        gutterBottom
                        sx={{ fontWeight: "bold" }}
                    >
                        Did we get it right?
                    </Typography>
                    <TextField
                        fullWidth
                        multiline
                        rows={4}
                        maxRows={8}
                        value={feedback}
                        onChange={(e) =>
                            setFeedback(e.target.value.slice(0, 500))
                        }
                        placeholder="Tell us your thoughts."
                        inputProps={{ maxLength: 500 }}
                        error={!!error}
                        helperText={
                            error || `${feedback.length}/500 characters`
                        }
                        sx={{ mb: 2 }}
                    />
                    <Typography
                        variant="body2"
                        sx={{ color: "text.secondary", fontSize: "12px" }}
                    >
                        By clicking Submit, you agree to our{" "}
                        <a href="/terms">Terms of Service</a> and{" "}
                        <a href="/privacy">Privacy Policy</a>.
                    </Typography>
                    <Box sx={{ py: "5px" }} />
                    <Button
                        variant="contained"
                        onClick={handleSubmit}
                        disabled={isSubmitting}
                        fullWidth
                    >
                        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                            {isSubmitting ? "Submitting..." : "Submit"}
                        </Typography>
                    </Button>
                </Paper>
            </Popover>
        </>
    );
};

export default FeedbackComponent;
