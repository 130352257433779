import React, { useState } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Typography from '@mui/material/Typography';
import ClickAwayListener from '@mui/material/ClickAwayListener';

// Create a custom theme with a pink tooltip
const theme = createTheme({
    components: {
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: 'rgba(255, 255, 255, 0.5)',
                    backdropFilter: 'blur(5px)',
                    color: 'rgba(0, 0, 0, 1)',
                    maxWidth: "90%",
                    fontSize: '0.875rem',
                    border: '1.5px solid #000000',
                    borderRadius: '10px',
                },
            },
        },
    },
});

const ClickTooltip = ({ text }) => {
    const [open, setOpen] = useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipToggle = () => {
        setOpen(!open);
    };

    return (
        <ThemeProvider theme={theme}>
          <ClickAwayListener onClickAway={handleTooltipClose}>
              <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                  <Tooltip
                      PopperProps={{
                          disablePortal: true,
                      }}
                      onClose={handleTooltipClose}
                      open={open}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      title={<Typography>{text}</Typography>}
                      arrow
                  >
                      <IconButton onClick={handleTooltipToggle}>
                          <HelpOutlineIcon fontSize="small" />
                      </IconButton>
                  </Tooltip>
              </div>
          </ClickAwayListener>
      </ThemeProvider>
  );
};

export default ClickTooltip;